<template>
  <div class="tabmenudemo-content">
    <div class="grid p-fluid" >
      <div class="col-12 p-2">
            <Menu :slug="slug" :active="active"/>
            
            <Toast />
            <div class="card">
                <h5>Starting Address:</h5>
                <div class="grid p-fluid">
                    <div class="col-6 lg:col-4">
                        <div class="field" v-if="pageLoading">
                            <Skeleton height="4rem" width="100%"/>
                        </div>
                        <div class="field" v-else>
                            <label for="country">Country <span style="color: #ff4444">*</span></label>
                            <InputText
                                :class="{'p-invalid': valid$.starting_point.country.$invalid && submitted}"
                                id="country" 
                                type="text" 
                                v-model="valid$.starting_point.country.$model"
                                disabled
                            />
                            <span v-if="valid$.starting_point.country.$error && submitted">
                                <span
                                    id="country-error"
                                    v-for="(error, index) of valid$.starting_point.country.$errors"
                                    :key="index"
                                >
                                    <small class="p-error">{{error.$message.replace("Value", "Country")}}</small>
                                </span>
                            </span>
                            <small
                                v-else-if="(valid$.starting_point.country.$invalid && submitted) || valid$.starting_point.country.$pending.$response"
                                class="p-error"
                            >
                                {{ valid$.starting_point.country.required.$message.replace("Value", "Country") }}
                            </small>
                        </div>
                    </div>
                    <div class="col-6 lg:col-4">
                        <div class="field" v-if="pageLoading">
                            <Skeleton height="4rem" width="100%"/>
                        </div>
                        <div class="field" v-else>
                            <label for="division">Division <span style="color: #ff4444">*</span></label>
                            <Dropdown
                                :class="{'p-invalid': valid$.starting_point.division.$invalid && submitted}"
                                id="division"
                                @change="getstartingDistrict(valid$.starting_point.division.$model)"
                                v-model="valid$.starting_point.division.$model"
                                :options="startingDivisionList"
                                optionLabel="value"
                                optionValue="value"
                                :filter="true"
                            />
                            <span v-if="valid$.starting_point.division.$error && submitted">
                                <span
                                    id="division-error"
                                    v-for="(error, index) of valid$.starting_point.division.$errors"
                                    :key="index"
                                >
                                    <small class="p-error">{{error.$message.replace("Value", "Division")}}</small>
                                </span>
                            </span>
                            <small
                                v-else-if="(valid$.starting_point.division.$invalid && submitted) || valid$.starting_point.division.$pending.$response"
                                class="p-error"
                            >
                                {{ valid$.starting_point.division.required.$message.replace("Value", "Division") }}
                            </small>
                        </div>
                    </div>
                    <div class="col-6 lg:col-4">
                        <div class="field" v-if="pageLoading">
                            <Skeleton height="4rem" width="100%"/>
                        </div>
                        <div class="field" v-else>
                            <label for="district">District <span style="color: #ff4444">*</span></label>
                            <Dropdown
                                :class="{'p-invalid': valid$.starting_point.district.$invalid && submitted}"
                                id="district"
                                @change="getstartingThana(valid$.starting_point.district.$model)"
                                v-model="valid$.starting_point.district.$model"
                                :options="startingDistrictList"
                                optionLabel="value"
                                optionValue="value"
                                :filter="true"
                            />
                            <span v-if="valid$.starting_point.district.$error && submitted">
                                <span
                                    id="district-error"
                                    v-for="(error, index) of valid$.starting_point.district.$errors"
                                    :key="index"
                                >
                                    <small class="p-error">{{error.$message.replace("Value", "District")}}</small>
                                </span>
                            </span>
                            <small
                                v-else-if="(valid$.starting_point.district.$invalid && submitted) || valid$.starting_point.district.$pending.$response"
                                class="p-error"
                            >
                                {{ valid$.starting_point.district.required.$message.replace("Value", "District") }}
                            </small>
                        </div>
                    </div>
                    <div class="col-6 lg:col-4">
                        <div class="field" v-if="pageLoading">
                            <Skeleton height="4rem" width="100%"/>
                        </div>
                        <div class="field" v-else>
                            <label for="thana">Thana <span style="color: #ff4444">*</span></label>
                            <Dropdown
                                :class="{'p-invalid': valid$.starting_point.thana.$invalid && submitted}"
                                id="thana"
                                v-model="valid$.starting_point.thana.$model"
                                :options="startingThanaList"
                                optionLabel="value"
                                optionValue="value"
                                :filter="true"
                            />
                            <span v-if="valid$.starting_point.thana.$error && submitted">
                                <span
                                    id="thana-error"
                                    v-for="(error, index) of valid$.starting_point.thana.$errors"
                                    :key="index"
                                >
                                    <small class="p-error">{{error.$message.replace("Value", "Thana")}}</small>
                                </span>
                            </span>
                            <small
                                v-else-if="(valid$.starting_point.thana.$invalid && submitted) || valid$.starting_point.thana.$pending.$response"
                                class="p-error"
                            >
                                {{ valid$.starting_point.thana.required.$message.replace("Value", "Thana") }}
                            </small>
                        </div>
                    </div>
                    <div class="col-6 lg:col-8">
                        <div class="field" v-if="pageLoading">
                            <Skeleton height="4rem" width="100%"/>
                        </div>
                        <div class="field" v-else>
                            <label for="line">Address <span style="color: #ff4444">*</span></label>
                            <InputText
                                :class="{'p-invalid': valid$.starting_point.line.$invalid && submitted}"
                                id="line"
                                type="text"
                                v-model="valid$.starting_point.line.$model"
                            />
                            <span v-if="valid$.starting_point.line.$error && submitted">
                                <span
                                    id="line-error"
                                    v-for="(error, index) of valid$.starting_point.line.$errors"
                                    :key="index"
                                >
                                    <small class="p-error">{{error.$message.replace("Value", "Line")}}</small>
                                </span>
                            </span>
                            <small
                                v-else-if="(valid$.starting_point.line.$invalid && submitted) || valid$.starting_point.line.$pending.$response"
                                class="p-error"
                            >
                                {{ valid$.starting_point.line.required.$message.replace("Value", "Line") }}
                            </small>
                        </div>
                    </div>
                </div>
                <Divider/>
                <h5>Destination Address:</h5>
                <div>
                    <div class="grid p-fluid">
                        <div class="col-6 lg:col-4">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="country">Country <span style="color: #ff4444">*</span></label>
                                <InputText
                                    :class="{'p-invalid': valid$.destination.country.$invalid && submitted}"
                                    id="country" 
                                    type="text" 
                                    v-model="valid$.destination.country.$model"
                                    disabled
                                />
                                <span v-if="valid$.destination.country.$error && submitted">
                                    <span
                                        id="country-error"
                                        v-for="(error, index) of valid$.destination.country.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Country")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.destination.country.$invalid && submitted) || valid$.destination.country.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.destination.country.required.$message.replace("Value", "Country") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-6 lg:col-4">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="division">Division <span style="color: #ff4444">*</span></label>
                                <Dropdown
                                    :class="{'p-invalid': valid$.destination.division.$invalid && submitted}"
                                    id="division"
                                    @change="getDestinationDistrict(valid$.destination.division.$model)"
                                    v-model="valid$.destination.division.$model"
                                    :options="destinationDivisionList"
                                    :filter="true"
                                    optionLabel="value"
                                    optionValue="value"
                                />
                                <span v-if="valid$.destination.division.$error && submitted">
                                    <span
                                        id="division-error"
                                        v-for="(error, index) of valid$.destination.division.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Division")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.destination.division.$invalid && submitted) || valid$.destination.division.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.destination.division.required.$message.replace("Value", "Division") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-6 lg:col-4">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="district">District <span style="color: #ff4444">*</span></label>
                                <Dropdown
                                    :class="{'p-invalid': valid$.destination.district.$invalid && submitted}"
                                    id="district"
                                    @change="getDestinationThana(valid$.destination.district.$model)"
                                    v-model="valid$.destination.district.$model"
                                    :options="destinationDistrictList"
                                    :filter="true"
                                    optionLabel="value"
                                    optionValue="value"
                                />
                                <span v-if="valid$.destination.district.$error && submitted">
                                    <span
                                        id="district-error"
                                        v-for="(error, index) of valid$.destination.district.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "District")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.destination.district.$invalid && submitted) || valid$.destination.district.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.destination.district.required.$message.replace("Value", "District") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-6 lg:col-4">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="thana">Thana <span style="color: #ff4444">*</span></label>
                                <Dropdown
                                    :class="{'p-invalid': valid$.destination.thana.$invalid && submitted}"
                                    id="thana"
                                    v-model="valid$.destination.thana.$model"
                                    :options="destinationThanaList"
                                    :filter="true"
                                    optionLabel="value"
                                    optionValue="value"
                                />
                                <span v-if="valid$.destination.thana.$error && submitted">
                                    <span
                                        id="thana-error"
                                        v-for="(error, index) of valid$.destination.thana.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Thana")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.destination.thana.$invalid && submitted) || valid$.destination.district.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.destination.thana.required.$message.replace("Value", "Thana") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-6 lg:col-8">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="line">Address <span style="color: #ff4444">*</span></label>
                                <InputText
                                :class="{'p-invalid': valid$.destination.line.$invalid && submitted}"
                                    id="line"
                                    type="text"
                                    v-model="valid$.destination.line.$model"
                                />
                                <span v-if="valid$.destination.line.$error && submitted">
                                    <span
                                        id="line-error"
                                        v-for="(error, index) of valid$.destination.line.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Line")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.destination.line.$invalid && submitted) || valid$.destination.district.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.destination.line.required.$message.replace("Value", "Line") }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <Button label="Update Address" :loading="loading" icon="pi pi-check" @click="updateAddress(!valid$.$invalid)" class="p-button-outlined" style="width: 80%; margin: auto; display: block;" />
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref} from 'vue';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import Axios from 'axios';
import { useRouter } from 'vue-router';
import GlobalValues from '../../../data/GlobalValues';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
import Menu from './TabMenu.vue';

export default {
    components:{
        Menu
    },
    setup(){
        const toast = useToast();
        const router = useRouter();
        const route = useRoute();

        const slug = ref();
        const tour = ref();
        const loading = ref(false);
        const pageLoading = ref(true);
        const active = ref(2);

        const submitted = ref(false);

        const globalValues = ref(new GlobalValues());

        const state = reactive({
            starting_point: {
                line: '',
                thana: '',
                district: '',
                division: '',
                country: 'Bangladesh',
            },
            destination: {
                line: '',
                thana: '',
                district: '',
                division: '',
                country: 'Bangladesh',
            },
        });

        const rules = {
            starting_point: {
                line: {  required, maxLength: maxLength(255) },
                thana: { required, maxLength: maxLength(255) },
                district: { required, maxLength: maxLength(255) },
                division: { required, maxLength: maxLength(255) },
                country: { required, maxLength: maxLength(255) },
            },
            destination: {
                line: {  required, maxLength: maxLength(255) },
                thana: { required, maxLength: maxLength(255) },
                district: { required, maxLength: maxLength(255) },
                division: { required, maxLength: maxLength(255) },
                country: { required, maxLength: maxLength(255) },
            },
        };

        const valid$ = useVuelidate (rules, state);

        slug.value = route.params.slug;

        const getSingleTour = () => {
            pageLoading.value = true;
            Axios
            .get("api/tour/view/"+slug.value)
            .then((res) => {
                if(res.data.response=="success") {
                    pageLoading.value = false;
                    tour.value=res.data.data;
                    
                    state.starting_point.line=res.data.data.starting_point.line;
                    state.starting_point.thana=res.data.data.starting_point.thana;
                    state.starting_point.country=res.data.data.starting_point.country;
                    state.starting_point.district=res.data.data.starting_point.district;
                    state.starting_point.division=res.data.data.starting_point.division;
                    state.destination.line=res.data.data.destination.line;
                    state.destination.thana=res.data.data.destination.thana;
                    state.destination.country=res.data.data.destination.country;
                    state.destination.district=res.data.data.destination.district;
                    state.destination.division=res.data.data.destination.division;

                    if(res.data.data) {
                        startingDivisionList.value = globalValues.value.getDivisions(state.starting_point.country);
                        destinationDivisionList.value = globalValues.value.getDivisions(state.destination.country);
                        startingDistrictList.value = globalValues.value.getDistricts(state.starting_point.division);
                        destinationDistrictList.value = globalValues.value.getDistricts(state.destination.division);
                        startingThanaList.value = globalValues.value.getThanas(state.starting_point.district);
                        destinationThanaList.value = globalValues.value.getThanas(state.destination.district);
                        return (
                            startingDivisionList.value? startingDivisionList.value:null,
                            startingDistrictList.value? startingDistrictList.value:null,
                            startingThanaList.value? startingThanaList.value:null,
                            destinationDivisionList.value? destinationDivisionList.value:null,
                            destinationDistrictList.value? destinationDistrictList.value:null,
                            destinationThanaList.value? destinationThanaList.value:null
                        );
                    }
                    getSingleTour();
                }
                else {
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
        getSingleTour();

        const startingDivisionList = ref();
        const destinationDivisionList = ref();
        const startingDistrictList = ref();
        const destinationDistrictList = ref();
        const destinationThanaList = ref();
        const startingThanaList = ref();

        const getstartingDivision = () => {
            startingDivisionList.value = globalValues.value.getDivisions(state.starting_point.country);

            state.starting_point.division = null;
            state.starting_point.district = null;
            state.starting_point.thana = null;
        }
        getstartingDivision();
        const getDestinationDivision = () => {
            destinationDivisionList.value = globalValues.value.getDivisions(state.destination.country);

            state.destination.division = null;
            state.destination.district = null;
            state.destination.thana = null;
        };
        getDestinationDivision();
        const getstartingDistrict = (division) => {
            startingDistrictList.value = globalValues.value.getDistricts(division);

            state.starting_point.district = null;
            state.starting_point.thana = null;
        };
        const getDestinationDistrict = (division) => {
            destinationDistrictList.value = globalValues.value.getDistricts(division);

            state.destination.district = null;
            state.destination.thana = null;
        };
        const getstartingThana = (district) => {
            startingThanaList.value = globalValues.value.getThanas(district);
            state.starting_point.thana = null;
        };
        const getDestinationThana = (district) => {
            destinationThanaList.value = globalValues.value.getThanas(district);
            state.destination.thana = null;
        };

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        };

        const backToDetails = () => {
            router.replace({ name: 'details', params: {slug: slug.value}});
        };

        const updateAddress = (isFormValid) =>{
            submitted.value = true;
            if (!isFormValid) {
                showToast("error", "Error", "Please Fill All Input Field");
                return;
            }
            loading.value = true;
            const formData1 = new FormData();

            formData1.append("starting_point[line]", state.starting_point.line);
            formData1.append("starting_point[thana]", state.starting_point.thana);
            formData1.append("starting_point[district]", state.starting_point.district);
            formData1.append("starting_point[division]", state.starting_point.division);
            formData1.append("starting_point[country]", state.starting_point.country);
            formData1.append("destination[line]", state.destination.line);
            formData1.append("destination[thana]", state.destination.thana);
            formData1.append("destination[district]", state.destination.district);
            formData1.append("destination[division]", state.destination.division);
            formData1.append("destination[country]", state.destination.country);

            formData1.append("_method", "PUT");

            Axios
            .post(`api/operator/tour/update-addresses/${tour.value.id}`, formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then((res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
                    getSingleTour();
                }
                else {
                    loading.value = false;
                    showToast("error", "Error", res.data.message);
                }
            })
            .catch((err) => {
                loading.value = false;
                showToast("error", "Error", "Something went wrong. Try again!");
                console.log(err);
            });
        };

        return{
            loading,
            pageLoading,
            slug,
            active,
            valid$,
            submitted,

            getstartingDivision,
            getstartingDistrict,
            getstartingThana,
            getDestinationDivision,
            getDestinationDistrict,
            getDestinationThana,

            startingDivisionList,
            startingDistrictList,
            startingThanaList,

            destinationDivisionList,
            destinationDistrictList,
            destinationThanaList,
            
            updateAddress,
            backToDetails
        }
    }

}
</script>

<style scoped>
.card{
    margin-bottom: 0px !important;
}
</style>